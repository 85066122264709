import React from 'react';
import { createRoot } from 'react-dom/client';
import { PageLayout } from 'ui/components/Layouts/PageLayout';
import { Header } from 'ui/components/Layouts/Header/Header';
import { Footer } from 'ui/components/Layouts/Footer/Footer';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('[data-react="ReactHeader"]');
  const footer = document.querySelector('[data-react="ReactFooter"]');

  if (header) {
    const props = JSON.parse(header.getAttribute('data-props') as string);

    const restricted = props.restricted === true;
    delete props.restricted;

    const headerRoot = createRoot(header);
    headerRoot.render(<PageLayout data={props}><Header restricted={restricted} /></PageLayout>);
  }

  if (footer) {
    const props = JSON.parse(footer.getAttribute('data-props') as string);

    const restricted = props.restricted === true;
    delete props.restricted;

    const footerRoot = createRoot(footer);
    footerRoot.render(<PageLayout data={props}><Footer restricted={restricted} /></PageLayout>);
  }
});
