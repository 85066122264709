import React from 'react';
import { Provider } from 'react-redux';
import store from 'ui/store';
import { ClientProvider } from 'ui/contexts/ClientContext';
import { UserProvider } from 'ui/contexts/UserContext';

interface Props {
  children: React.ReactNode;
  data: { client: unknown, user: unknown, todos: unknown }
}

export const PageLayout: React.FC<Props> = ({ children, data }) => (
  <React.StrictMode>
    <Provider store={store(data)}>
      <ClientProvider>
        <UserProvider>
          <div className="min-w-full max-w-screen-lg">{children}</div>
        </UserProvider>
      </ClientProvider>
    </Provider>
  </React.StrictMode>
);

export default PageLayout;
